import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


export const IntegrationsCallbackPage = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const params = {
    state: urlParams.get("state"),
    code: urlParams.get("code"),
    location: urlParams.get("location"),
    "accounts-server": urlParams.get("accounts-server"),
  };

  useEffect(() => {
    return navigate("/integrations", {state: params})
  }, []);


  return (
    <div>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Integrating....
        </h1>
        <div className="content__body">
          <p id="page-description">
            <span>Redirecting to Integrations.</span>
          </p>
        </div>
      </div>
    </div>
  );
};
