import axios from "../zustand/utils";
import { useAuth0 } from "@auth0/auth0-react";

export const useEnrichment = () => {
  const { getAccessTokenSilently } = useAuth0();

  const uploadFile = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const config = {
      method: "post",
      url: "/lead/file/upload",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    };

    try {
      const response = await axios(config);
      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const getAllFiles = async () => {
    const config = {
      method: "get",
      url: `/lead/file/history`,
      headers: {
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    };

    try {
      const response = await axios(config);

      if (response.data) {
        return response.data?.data;
      }

      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }

    return [];
  };

  const getFileDownloadLink = async (fileId) => {
    const config = {
      method: "get",
      url: `/lead/file/download?file_id=${fileId}`,
      headers: {
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    };

    try {
      const response = await axios(config);

      if (response.data) {
        return response.data?.data?.download_url;
      }

      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return {
    uploadFile,
    getAllFiles,
    getFileDownloadLink,
  };
};
