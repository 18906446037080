import useIntegrationStore from "../zustand/integration";
import axios from "../zustand/utils";
import { useAuth0 } from "@auth0/auth0-react";

export const useIntegration = () => {
  const setIntegrations = useIntegrationStore((s) => s.setIntegrations);
  const { getAccessTokenSilently } = useAuth0();

  const integrateWithCRM = async (params, integrationID) => {
    const integrations = useIntegrationStore.getState().integrations;

    try {
      const config = {
        method: "post",
        url: `/auth/crm`,
        data: params,
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        const copyIntegration = [...integrations];
        for (let integration of copyIntegration) {
          console.log(integration.id, integrationID);
          if (integration["id"] === integrationID) {
            integration["integrated"] = true;
          }
        }
        console.log(copyIntegration);
        setIntegrations(copyIntegration);
        return true
      }
    } catch (err) {
      console.error("Error in data fetch:", err);
      return false;
    }
  };


  return { integrateWithCRM };
};
