import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import React, {useEffect} from "react";
import { PageLoader } from "./pageLoader";

export const AuthenticationGuard = ({ component }) => {
  const { isAuthenticated } = useAuth0();

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <PageLoader />
      </div>
    ),
  });

  return <Component />;
};
