import React, { useEffect, useRef } from "react";
import {
  Box,
  Icon,
  Link,
  Text,
  SimpleGrid,
  Flex,
  useColorModeValue,
  Grid,
  GridItem,
  background,
} from "@chakra-ui/react";

import MiniStatistics from "../components/card/MiniStatistics";
import IconBox from "../components/icons/IconBox";
import EmptyState from "../components/EmptyState";
import {
  MdFileCopy,
  MdOutlinePeopleAlt,
  MdPeople,
  MdFilterList,
  MdArrowForward,
} from "react-icons/md";
import { HiCurrencyDollar } from "react-icons/hi2";
import PieCard from "./components/PieCard";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays, subDays } from "date-fns";
import { useState } from "react";
import useDashboardData from "../hooks/useDashboardData";
import { PageLoader } from "../components/pageLoader";
import useOnClickOutside from "../hooks/useOnClickOutside";

const Dashboard = (props) => {
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  let mainText = useColorModeValue("navy.700", "white");
  const datePickerRef = useRef(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    startDate: new Date(2024, 0, 1),
    endDate: new Date(),
    key: "selection",
  });

  const setDateRange = (range) => {
    setSelectedDate(range.selection);
  };
  useOnClickOutside(datePickerRef, () => setShowDatePicker(false));

  const {
    isFetching,
    error,
    funnelData = {},
    leadAnalysisData,
  } = useDashboardData({
    startDate: selectedDate?.startDate,
    endDate: selectedDate?.endDate,
  });

  return (
    <Box
      pt={{ base: "130px", md: "20px", xl: "20px" }}
      height="100%"
      overflow="inherit"
      position="relative"
    >
      <Flex flexDirection="column" alignItems="flex-end" width={"100%"}>
        <div style={{ position: "relative" }}>
          <button
            onClick={() => setShowDatePicker(!showDatePicker)}
            style={{ fontSize: "1rem", fontWeight: 300 }}
            className="flex justify-start items-center"
          >
            <div className="text-[#3a4c87] flex flex-wrap justify-start items-start">
              <span>{selectedDate.startDate.toDateString()}</span>
              <span className="font-bold mx-2 pt-1">
                <MdArrowForward />
              </span>
              <span>{selectedDate.endDate.toDateString()}</span>
            </div>
            <span className=" font-bold text-xl ml-7 flex justify-center items-center gap-2">
              Filter
              <MdFilterList />
            </span>
          </button>{" "}
          {showDatePicker ? (
            <div
              ref={datePickerRef}
              style={{
                position: "absolute",
                zIndex: 1,
                right: 0,
                background: "white",
              }}
            >
              <DateRangePicker
                onChange={(item) => setDateRange(item)}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={[selectedDate]}
                direction="horizontal"
                preventSnapRefocus={true}
              />
              <button
                className="float-end bg-blue-500 text-white px-9 py-2 mb-3 mr-3 rounded-sm"
                onClick={() => {
                  setShowDatePicker(false);
                }}
              >
                Select
              </button>
            </div>
          ) : null}
        </div>
      </Flex>

      <Link
        color={mainText}
        href="#"
        bg="inherit"
        borderRadius="inherit"
        fontWeight="bold"
        fontSize="34px"
        _hover={{ color: { mainText } }}
        _active={{
          bg: "inherit",
          transform: "none",
          borderColor: "transparent",
        }}
        _focus={{
          boxShadow: "none",
        }}
      >
        Funnels
      </Link>

      {isFetching || !funnelData || funnelData["Processed Leads"][0].count === 0 ? (
        <Box pt={3} mt={3} mb={5}>
          <EmptyState variant={2}>
            {isFetching ? <PageLoader /> : <></>}
            {isFetching ? (
              <>{"Loading Funnel Data..."}</>
            ) : (
              <>{"No Funnel Data Yet"}</>
            )}
          </EmptyState>
        </Box>
      ) : (
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          marginTop={10}
          marginBottom={10}
        >
          {Object?.entries(funnelData ?? {})?.map(([funnel, data]) => (
            <Flex
              flexDirection="column"
              flex={1}
              marginRight={10}
              align="center"
            >
              <Text fontSize={18} fontWeight={600}>
                {funnel}
              </Text>
              <SimpleGrid columns={1} rows={4} gap="20px" mb="20px" mt="20px">
                <MiniStatistics
                  style={{ minHeight: "108px" }}
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdPeople}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="All Leads"
                  value={data[0].count}
                  growth={funnel === "Profiled Leads" && funnelData["Processed Leads"] && funnelData["Processed Leads"][0].count ? Math.round(funnelData["Profiled Leads"][0].count / funnelData["Processed Leads"][0].count * 1000 ) /10 + "%": null}
                  highlight={true}
                  extraText="Enrichment"
                />

                <Flex paddingLeft={5} paddingRight={5}>
                  <MiniStatistics
                    startContent={
                      <IconBox
                        w="56px"
                        h="56px"
                        bg={boxBg}
                        icon={
                          <Icon
                            w="32px"
                            h="32px"
                            as={MdOutlinePeopleAlt}
                            color={brandColor}
                          />
                        }
                      />
                    }
                    name="Qualified"
                    growth={data[1].percentage + "%"}
                    value={data[1].count}
                  />
                </Flex>

                <Flex paddingLeft={7} paddingRight={7}>
                  <MiniStatistics
                    startContent={
                      <IconBox
                        w="56px"
                        h="56px"
                        bg={boxBg}
                        icon={
                          <Icon
                            w="32px"
                            h="32px"
                            as={MdFileCopy}
                            color={brandColor}
                          />
                        }
                      />
                    }
                    name="Prospected"
                    growth={data[2].percentage + "%"}
                    value={data[2].count}
                  />
                </Flex>

                <Flex paddingLeft={9} paddingRight={9}>
                  <MiniStatistics
                    startContent={
                      <IconBox
                        w="56px"
                        h="56px"
                        bg={boxBg}
                        icon={
                          <Icon
                            w="32px"
                            h="32px"
                            as={HiCurrencyDollar}
                            color={brandColor}
                          />
                        }
                      />
                    }
                    name="Converted"
                    highlight={funnel === "Profile Fit Leads" || funnel === "Intent Leads" || funnel === "RQLs"}
                    growth={data[3].percentage + "%"}
                    value={data[3].count}
                  />
                </Flex>
              </SimpleGrid>
            </Flex>
          ))}
        </Flex>
      )}
      {/*<Flex*/}
      {/*  flexDirection="row"*/}
      {/*  justifyContent='space-between'*/}
      {/*  marginTop={10}*/}
      {/*>*/}

      <Link
        color={mainText}
        href="#"
        bg="inherit"
        borderRadius="inherit"
        fontWeight="bold"
        fontSize="34px"
        _hover={{ color: { mainText } }}
        _active={{
          bg: "inherit",
          transform: "none",
          borderColor: "transparent",
        }}
        _focus={{
          boxShadow: "none",
        }}
        mt={50}
      >
        Lead Analysis
      </Link>

      {isFetching || !leadAnalysisData || Object.keys(leadAnalysisData.age_range).length === 0 ? (
        <Box pt={3} mt={3} mb={5}>
          <EmptyState variant={1}>
            {isFetching ? <PageLoader /> : <></>}
            {isFetching ? (
              <>{"Loading Lead Analysis Data..."}</>
            ) : (
              <>{"No Lead Analysis Data Yet"}</>
            )}
          </EmptyState>
        </Box>
      ) : (
        <SimpleGrid columns={2} rows={2} gap="20px" mb="20px" mt="30px">
          {Object?.entries(leadAnalysisData ?? {})?.map((item) => {
            return (
              <PieCard
                style={{ textTransform: "Capitalize" }}
                title={item?.[0].replace("_", " ")}
                data={item?.[1]}
              />
            );
          })}
        </SimpleGrid>
      )}
      {/*</Flex>*/}
    </Box>
  );
};

export default Dashboard;
