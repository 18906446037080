import { create } from "zustand";
import { persist } from "zustand/middleware";

const useIntegrationStore = create(
  persist(
    (set) => ({
      showPopup: false,
      selectedIntegration: null,
      integrations: [
        {
          name: "Zoho CRM",
          id: "zoho",
          status: "Live",
          logo: "/logo_zoho.webp",
          enabled: true,
          integrated: false,
        },
        {
          name: "LeadSquared",
          id: "leadsquared",
          status: "Live",
          logo: "/logo_leadsquared.webp",
          enabled: true,
          integrated: false,
        },
        {
          name: "Salesforce",
          status: "Live",
          id: "salesforce",
          logo: "/logo_salesforce.jpeg",
          enabled: true,
          integrated: false,
        },
        {
          name: "HubSpot",
          status: "Live",
          id: "hubspot",
          logo: "/logo_hubspot.png",
          enabled: true,
          integrated: false,
        },
      ],
      closePopup: () => set({ showPopup: false }),
      openPopup: () => set({ showPopup: true }),
      setSelectedIntegration: (selected) =>
        set({ selectedIntegration: selected }),
      setIntegrations: (integrationList) =>
        set({ integrations: integrationList }),
    }),
    {
      name: "integrations",
      version: 1,
      migrate: (persistedState, version) => {
        if (version === 0) {
          return {
            ...persistedState,
            integrations: persistedState.integrations.map((integration) =>
              integration.id === "leadsquared"
                ? { ...integration, enabled: true }
                : integration
            ),
          };
        }
        return persistedState;
      },
    }
  )
);

export default useIntegrationStore;

