import React, { useEffect } from "react";
import { Box, Button, Flex, Image, Link, Text } from "@chakra-ui/react";
import Card from "../components/card/Card.js";
import { INTEGRATIONS_CALLBACK_URL } from "../config";
import useIntegrationStore from "../zustand/integration";
import useAppStore from "../zustand/app.js";
import { MODALS } from "../types/modal.types.js";
import useAuthStore from "../zustand/auth";
import { useLocation } from "react-router-dom";
import { useIntegration } from "../hooks/integration";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const IntegrationItem = ({
  name,
  status,
  logo,
  onStatusClick,
  integrated,
  enabled,
}) => (
  <Card p="20px">
    <Flex direction={{ base: "column" }} justify="center">
      <Box mb={{ base: "20px", "2xl": "20px" }} position="relative">
        <Image
          src={logo}
          w={{ base: "100%", "3xl": "100%" }}
          h="100px"
          borderRadius="20px"
          style={{objectFit: 'cover'}}
        />
      </Box>
      <Flex flexDirection="column" justify="space-between" h="100%">
        <Flex
          justify="space-between"
          direction={{
            base: "row",
            md: "column",
            lg: "row",
            xl: "column",
            "2xl": "row",
          }}
          mb="auto"
        >
          <Flex direction="column">
            <Text
              fontSize={{
                base: "xl",
                md: "lg",
                lg: "lg",
                xl: "lg",
                "2xl": "md",
                "3xl": "lg",
              }}
              mb="5px"
              fontWeight="bold"
              me="14px"
            >
              {name}
            </Text>
          </Flex>
        </Flex>
        <Flex
          align="start"
          justify="space-between"
          direction={{
            base: "row",
            md: "column",
            lg: "row",
            xl: "column",
            "2xl": "row",
          }}
          mt="25px"
        >
          <Button
            onClick={() => onStatusClick(name)}
            variant={integrated ? "outline" : "darkBrand"}
            colorScheme="green"
            fontSize="sm"
            fontWeight="500"
            borderRadius="70px"
            isDisabled={integrated || !enabled}
            px="24px"
            py="5px"
          >
            {integrated ? "Live" : enabled ? "Connect" : "Coming Soon"}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  </Card>
);

const zohoAuthLink = (clientId, redirectUri, client) =>
  `https://accounts.zoho.in/oauth/v2/auth?response_type=code&client_id=${clientId}&scope=ZohoCRM.modules.ALL,ZohoCRM.settings.ALL,ZohoCRM.notifications.ALL,ZohoCRM.org.READ&redirect_uri=${redirectUri}&prompt=consent&access_type=offline&state=${client}:zoho`;

const salesforceAuthLink = (clientId, redirectUri, client) => 
  `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=api%20refresh_token&state=${client}:salesforce`

//todo = have to refine these scopes
const hubspotAuthLink = (clientId, redirectUri, client) => 
`https://app.hubspot.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&state=${client}:hubspot&scope=crm.schemas.contacts.write%20oauth%20crm.objects.leads.read%20crm.objects.leads.write%20crm.objects.contacts.write%20crm.objects.custom.read%20crm.objects.custom.write%20crm.schemas.contacts.read%20crm.objects.contacts.read`


const Popup = () => {
  const showPopup = useIntegrationStore((state) => state.showPopup);
  const selected = useIntegrationStore((state) => state.selected);
  const closePopup = useIntegrationStore((state) => state.closePopup);

  return (
    <div className={`popup-background ${showPopup ? "visible" : "hidden"}`}>
      <div className="popup">
        <h2>{selected && selected.name}</h2>
        <img
          src={selected && selected.logo}
          alt={`${selected && selected.name} logo`}
          className="integration-logo"
        />
        <button className="close-button" onClick={() => closePopup(selected)}>
          Close
        </button>
      </div>
    </div>
  );
};

const Integration = () => {
  const toggleModal = useAppStore((state) => state.toggleModal);
  const integrations = useIntegrationStore((state) => state.integrations);
  const { integrateWithCRM } = useIntegration();
  const location = useLocation();
  let params = {...location.state}
  const setSelectedIntegration = useIntegrationStore(
    (state) => state.setSelectedIntegration
  );
  
  useEffect(() => {
    console.log(params);
    if (params && params.state) {
      const stateVal = params.state
      if (stateVal) {
        const crm = stateVal.split(':')[1]
        console.log(crm)
        integrateWithCRM(params, crm).then((val) => {
          if (val) {
            toast.success("Integration successful!");
          } else {
            toast.error("Integration failed!");
          }
        });

      }
    }
  }, []);


  const handleCredentialRedirect = (id) => {
    if (id === "zoho") {
      window.location.href = zohoAuthLink(
        "1000.FVD8PK3ZCJNXELSDP4UXIFGZ7WIBSQ",
        INTEGRATIONS_CALLBACK_URL,
        useAuthStore.getState().user.org_name
      );
    } else if (id === "leadsquared") {
      toggleModal(MODALS.integration, {
        id: "leadsquared",
        name: "LeadSquared",
      });
    } else if (id === "salesforce") {
      window.location.href = salesforceAuthLink(
        "3MVG9NnK0U_HimV79TCU1BHvwdN5HG1xcAJ7B4h28ahLx8Umqp6zzGWYFQheeZ_._cl1LFriB2qmEyoBaYy2r",
        INTEGRATIONS_CALLBACK_URL,
        useAuthStore.getState().user.org_name
      );
    }
      else if(id === 'hubspot'){
        window.location.href = hubspotAuthLink(
          "8185a921-a1bb-41dd-ba33-bc18db2824b5",
          INTEGRATIONS_CALLBACK_URL,
          useAuthStore.getState().user.org_name
        );
    }
  };

  return (
    <div className="app">
      <main>
        <ToastContainer />
        <div className="header">
          <h1>Integrations</h1>
          <p>Start syncing data with your favorite CRM now!</p>
        </div>

        <div className="integration-container">
          {integrations &&
            integrations.map((integration) => (
              <IntegrationItem
                key={integration.name}
                {...integration}
                onStatusClick={() => {
                  setSelectedIntegration(integration);
                  handleCredentialRedirect(integration.id);
                }}
              />
            ))}
        </div>
      </main>
      <Popup />
    </div>
  );
};

export default Integration;
